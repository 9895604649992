.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: var(--font-size-3);
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.pageTitleSummery {
    font-size: var(--font-size-7);
    color: var(--gray-color-2);
    font-weight: 400;
    margin-top: -5px;
}

.topActionButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.tabsList {
    background-color: var(--primary-color-2);
    border-radius: 0px;
    border: none;
}

.imageTabContainer {
    /* background-color: var(--primary-color-3); */
    color: var(--primary-color-1);
    font-weight: 600;
    /* margin: 0px; */
    padding: 5px;
    border-radius: 3px;
    border: 1px solid var(--primary-color-1);
}

.imageTabContainerActive {
    background-color: var(--active-color-1);
    padding: 8px;
}

.imageItemContainer {
    width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageItem {
    width: 100%;
    height: auto;
}

.formLabelName {
    font-size: var(--font-size-6);
    color: var(--gray-color-2);
    font-weight: 600;
}

.formContainer {
    margin-bottom: 20px;
}

.formLableContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.formRequied {
    color: tomato;
}

.lable {
    color: var(--gray-color-2);
    font-size: var(--font-size-6);
}

.formInputContainer {
    background-color: #fff;
}

.formInput {
    height: 31px;
    border: 1.5px solid var(--gray-color-4);
    border-radius: 4px;
    width: 100%;
}

.formInput:focus {
    outline: none;
}

.inputCommentData {
    background-color: white;
}



.formInputFileUploadContainer {
    display: flex;
    width: 100%;
    border-radius: 4px;
    border: 1px dashed var(--gray-color-4);
}

.formInputFileUpload {
    width: 100%;
}

.formInputFileUpload label {
    height: 100px !important;
    border: none;
    width: 100%;
}

.formInputFileUpload svg {
    color: var(--gray-color-4);
}

.newsTypeContainr {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.imagePreviewContainer {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    background-color: #fff;
}

.previewImage {
    width: 200px;
    height: auto;
    max-width: 100vw;
    border: 1px solid var(--gray-color-4);
    aspect-ratio: 16/9;
}

.removeImagePreview {
    color: var(--error-color-1);
    font-size: 30px;
    width: 70px;
    height: inherit;
}

.modelPreviewContainer {
    width: 90vw;
    background-color: #fff;
}

.modelPreviewHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--gray-color-4);
}

.modelPreviewBodyContainer {
    max-height: 70vh;
    overflow-y: auto;
}

.modelPreviewFooterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top: 1px solid var(--gray-color-4);
    gap: 10px;
}
.newsPreviewContainer{
    padding: 10px;
    width: 100%;
}
.newsTitle{
    font-size: var(--font-size-2);
    color: var(--primary-color-1);
    font-weight: 600;
    text-transform: capitalize;
}
.newsDescription{
    font-size: var(--font-size-5);
    color: var(--gray-color-2);
    font-weight: 400;
}
.newsMedias{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}
.newsMediaContainer{
    max-width: 200px;
    height: auto;
}
.newsMedia{
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}


.mediasContainer{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    color: var(--gray-color-2);
    font-weight: 600;
    margin-bottom: 10px;
}