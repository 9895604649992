.cardImageContainer {
    width: 90%;
    /* height: 110px; */
    overflow: hidden;
    border: 1px solid #e6e6e6;
    box-shadow: 0 0 5px 0 #ccc;
    border-radius: 15px;
    aspect-ratio: 1/1;
    margin: 0px auto;
    transition: all .3s ease;
}

.cardImageContainer:hover {
    transform: scale(1.5);
    border: 1px solid rgba(1,35,90,1);;
    box-shadow: 0 0 10px 0 rgba(1,35,90,1);
    cursor: pointer;
}

.cardImage {
    border-radius: 15px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #ffffff;
    /* transition: all .5s ease; */
}

/* .cardImageContainer .cardImage:hover {
    transform: scale(1.2);
} */

.titleContainer {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: var(--font-size-6);
    font-weight: 500;
    margin: 0px auto 7.5% auto;
    transition: all .3s ease;
}

.cardImageContainer:hover + .titleContainer{
    transform: scale(1.1);
    margin: 3% auto 0% auto;
}

.title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--gray-color-2);
}

.dashboardLinkIcon {
    cursor: pointer;
    color: var(--gray-color-2);
}

/* small devices (mobile) */
@media only screen and (max-width: 576px) {
    .cardImageContainer {
        /* height: 180px; */
    }
}

/* Middle devices (tablets) */
@media only screen and (min-width: 768px) {
    .cardImageContainer {
        /* height: 150px; */
    }
}

/* large devices (laptop) */
@media only screen and (min-width: 992px) {
    .cardImageContainer {
        /* height: 110px; */
    }
}