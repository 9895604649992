.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: var(--font-size-3);
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.pageTitleSummery {
    font-size: var(--font-size-7);
    color: var(--gray-color-2);
    font-weight: 400;
    margin-top: -5px;
}

.subTitle {
    font-size: var(--font-size-3);
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.subTitleSummeryNote {
    font-size: var(--font-size-7);
    color: var(--error-color-1);
    font-weight: 500;
    margin-top: -5px;
}

.subTitleSummery {
    font-size: var(--font-size-7);
    color: var(--gray-color-2);
    font-weight: 400;
    margin-top: -5px;
}

.topRightButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}



.formContainer {
    margin-bottom: 20px;
}

.formLableContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.formRequied {
    color: tomato;
}

.lable {
    color: var(--gray-color-2);
    font-size: var(--font-size-6);
}

.formInputContainer {
    background-color: #fff;
}

.formInput {
    height: 31px;
    border: 1.5px solid var(--gray-color-4);
    border-radius: 4px;
    width: 100%;
}

.formInput:focus {
    outline: none;
}

.formInputTextArea {
    height: 100px;
    border: 1px solid var(--gray-color-4);
    border-radius: 4px;
    width: 100%;
    resize: none;
}

.formInputTextArea:focus {
    outline: none;
}

.formInputFileUploadContainer {
    display: flex;
    width: 100%;
    border-radius: 4px;
    border: 1px dashed var(--gray-color-4);
}

.formInputFileUpload {
    width: 100%;
}

.formInputFileUpload label {
    height: 100px !important;
    border: none;
    width: 100%;
}

.formInputFileUpload svg {
    color: var(--gray-color-4);
}

.uploadedImageContainer {
    height: 100px;
    width: auto;
    transition: all 0.5s ease-in-out;
}

.uploadedImg {
    height: 100px;
    width: 100%;
}

.uploadedImageContainer:hover {
    transform: scale(3, 2) translateX(-20%);
    background-color: wheat;
    box-shadow: 0px 0px 3px 0px #ccc;
}

.uploadedVideoContainer {
    height: 100px;
    margin: 0px 10px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.uploadedVideoContainer svg {
    font-size: 50px;
    color: #0063C3;
}

.uploadedVideoViewContainer {
    padding: 10px;
    width: 400px;
    max-width: 90vw;
    height: auto;
    background-color: white;
    border-radius: 10px;
}

.uploadedVideoViewHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.uploadedVideo {
    width: 100%;
    height: auto;
}

.uploadedVideo::-webkit-media-controls-timeline {
    display: none;
}


.tutorialText>.ql-editor {
    background-color: red;
    height: 200px;
}

.checkboxOptionsTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.checkboxOptionsTitle {
    color: var(--gray-color-2);
    font-size: var(--font-size-4);
}

.checkboxOptionsBodyContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: var(--gray-color-2);
    margin-left: 20px;
}

.checkboxOptionContainer {
    width: 100%;
}

.checkboxOption {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    color: var(--gray-color-2);
    font-size: var(--font-size-5);

}

.reportPreview {
    background-color: #fff;
    border: 1px solid var(--gray-color-4);
    box-shadow: 0px 0px 3px 0px #ccc;
}