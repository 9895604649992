.date_filter_label_wrapper {
    padding-left: 10px;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.30);
    border-radius: 4px;
    position: relative;
    box-sizing: border-box;
    height: 40px;
}

.date_filter_label_wrapper:hover {
    border: 1px solid rgba(0, 0, 0, 1);
}

.date_filter_label_wrapper_focus {
    border: 2px solid #1976D2;
}

.date_filter_label {
    position: absolute;
    top: -10px;
    left: 10px;
    background-color: white;
    padding-left: 6px;
    padding-right: 6px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.40);
}

.date_filter_wrapper {
    width: 100%;
}

.date_filter_wrapper>div {
    border: none !important;
    outline: none !important;
}