.card {
    width: 95%;
    aspect-ratio: 1/1;
    padding: 5px;
    perspective: 1000px;
    margin: 0px auto 40px auto;
    border: 1px solid #e6e6e6;
    border-radius: 15px;
    cursor: pointer;
}

.cardInner {
    width: 100%;
    height: 100%;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.7s;
}

.card:hover .cardInner {
    /* transform: rotateY(180deg); */
}

.cardFront,
.cardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.cardFront {
    /* transform: rotateY(0deg); */
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 15px;
}

.cardBack {
    color: var(--gray-color-6);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    padding: 10px;
    position: absolute;
    width: 100%;
    height: 25%;
    z-index: 9;
    bottom: 0;
    /* background: #3f50ecd3; */
    background: rgba(1, 35, 90, 1);
    transition: all 0.5s ease;
    opacity: 1;
}

.cardInner:hover .cardBack {
    height: 70%;
    /* border-radius: 15px; */
    background: linear-gradient(180deg, rgba(1, 35, 90, 1) 0%, #024f99 50%, #0063c3 100%);

}

.cardImage {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 15px;
    background: linear-gradient(290deg, var(--gray-color-5), var(--gray-color-6), var(--gray-color-5), var(--gray-color-6), var(--gray-color-5), var(--gray-color-6));
    background-size: 360% 360%;
    animation: gradient-animation 12s ease infinite;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    font-size: var(--font-size-6);
    font-weight: 500;
}

.title {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--gray-color-2);
}

.dashboardLinkIcon {
    cursor: pointer;
    color: var(--gray-color-2);
}

.backTitle {
    font-size: var(--font-size-8);
    text-transform: capitalize;
    color: var(--gray-color-6);
    border-bottom: 1px solid var(--gray-color-5);
}

@media only screen and (min-width: 1300px) {
    .backTitle {
        font-size: var(--font-size-7);
    }
}

.cardInner:hover .backTitle {
    font-size: var(--font-size-5);
}

.backDescription {
    font-size: var(--font-size-7);
    color: var(--gray-color-5);
    display: none;
    /* text-align: justify; */
}

.cardInner:hover .backDescription {
    font-size: var(--font-size-6);
    display: block;
}

/* small devices (mobile) */
@media only screen and (max-width: 576px) {
    .card {
        /* height: 210px; */
    }

    .cardImage {
        /* height: 180px; */
    }
}

/* Middle devices (tablets) */
@media only screen and (min-width: 768px) {
    .card {
        /* height: 180px; */
    }

    .cardImage {
        /* height: 150px; */
    }
}

/* large devices (laptop) */
@media only screen and (min-width: 992px) {
    .card {
        /* height: 150px; */
    }

    .cardImage {
        /* height: 110px; */
    }
}



@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}