.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: 20px;
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.topRightButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.searchControl {
    width: 100%;
    height: 32px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 8px;
    outline: none;
}

.totalCount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
}

.tableActionButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
.tableActionButtonContainer .btnAction svg{
    font-size: var(--font-size-5);
}
.btnEdit {
    /* color: #0063C3; */
    color: var(--gray-color-3);
}

.btnDelete {
    /* color: #E30404; */
    color: var(--gray-color-3);
}

.projectRootContainer {
    width: 400px;
    max-width: 90vw;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.projectContainer {
    padding: 10px;
}

.projectHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectHeader {
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-color-2)
}

.btnClose {
    font-size: 20px;
    color: var(--gray-color-3);
    cursor: pointer;
}

.projectBodyContainer {
    padding: 0px;
}

.addProjectContainer {
    margin-top: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}
