.searchBoxRootContainer {
    width: 400px;
    background-color: #fff;
}

.searchBoxContainer {
    border: 1px solid #ccc;
}

.searchBoxHeader {
    background-color: #0063c3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.searchBoxHeaderTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    color: white;
    width: 100%;
    text-align: start;
}

.searchBoxHeaderCloseButton {
    color: white;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.searchBoxBody {
    padding: 10px;
}

.searchBoxBodyContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.searchBoxBodyContainerSearchBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #333131;
}

.inputSearchBox {
    width: 100%;
    height: 100%;
    padding: 5px;
    outline: none;
    border: 0px;
    color: black;
}

.inputSearchBox:focus {
    outline: none;
    border: 0px;
}

.inputSearchBox:focus-visible {
    outline: none;
    border: 0px;
}

.inputSearchBox:focus-within {
    outline: none;
    border: 0px;
}

.inputSearchBox:focus-visible {
    outline: none;
    border: 0px;
}


.inputSearchBoxIconContainer {
    width: 40px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.inputSearchBoxIcon {
    width: 100%;
    height: 100%;
    color: #656262;
}

.cancelSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 40px;
    color: #ccc;
}

.searchResultContainer {
    color: rgb(67, 62, 62);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
}

.searchResultWrapper {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
}

.searchResultItem {
    padding: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 5px 0px #333131;
    border-radius: 3px;
}

.searchResultItemTitle {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #0063c3;
    width: 100%;
    text-align: start;
}
.searchResultItemDetail{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.searchResultItemDetailText{
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #656262;
    width: 100%;
    text-align: start;
}