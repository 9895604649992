.wellcomeTextContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: var(--font-size-1);
    color: var(--primary-color-dark-1);
}

.userName {
    font-size: var(--font-size-1);
    font-weight: bold;
    margin: 0px 10px 0px 10px;
    color: var(--primary-color-1);
}

.title {
    font-size: var(--font-size-3);
    font-weight: bold;
    color: var(--primary-color-1);
    margin-bottom: 5px;
}

.pagesContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media only screen and (min-width: 992px) {
    .pagesContainer {
        flex-direction: row;
    }
}

.pageContainer {
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 3px 0px #cccccc;
    border-radius: 10px;
    padding: 5px;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
}

.pageContainer:hover {
    width: 150%;
    max-width: 95vw;
    background-color: #e8f4f8;
}

.pageTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--gray-color-2);
}

.pageTitle {
    font-size: var(--font-size-5);
    font-weight: bold;
    color: var(--gray-color-2);
}

.pageDetailContainer {
    margin-top: 10px;
}

.pageDetailsHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: var(--font-size-6);
    margin-bottom: 5px;
    font-weight: bold;
    border-bottom: 1px solid var(--gray-color-3);
}

.sectionContainer {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: var(--font-size-5);
    margin-bottom: 5px;
}

.reportCount {
    background-color: var(--gray-color-2);
    font-size: var(--font-size-7);
    color: #ffffff;
    width: 25px;
    border-radius: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
}