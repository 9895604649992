.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: var(--font-size-3);
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.pageTitleSummery {
    font-size: var(--font-size-7);
    color: var(--gray-color-2);
    font-weight: 400;
    margin-top: -5px;
}

.pageSubTitleContainer {
    border-bottom: 3px solid var(--primary-color-1);
    margin: 10px 0px 10px 0px;
}

.pageSubTitle {
    background-color: var(--primary-color-1);
    font-size: var(--font-size-4);
    color: var(--gray-color-5);
    font-weight: 600;
    text-transform: capitalize;
    padding: 5px 10px 5px 10px;
}

.listActionButtonContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    gap: 5px;
}

.tabsList {
    background-color: var(--primary-color-2);
    border-radius: 0px;
    border: none;
}


.imageTabContainer {
    /* background-color: var(--primary-color-3); */
    color: var(--primary-color-1);
    font-weight: 600;
    /* margin: 0px; */
    padding: 5px;
    border-radius: 3px;
    border: 1px solid var(--primary-color-1);
}

.imageTabContainerActive {
    background-color: var(--active-color-1);
    padding: 8px;
}

.imageItemContainer {
    width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageItem {
    width: 100%;
    height: auto;
}

.formLabelName {
    font-size: var(--font-size-6);
    color: var(--gray-color-2);
    font-weight: 600;
}

.formContainer {
    margin-bottom: 20px;
}

.formLableContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.formRequied {
    color: tomato;
}

.lable {
    color: var(--gray-color-2);
    font-size: var(--font-size-6);
}

.formInputContainer {
    background-color: #fff;
}

.formInput {
    height: 31px;
    border: 1.5px solid var(--gray-color-4);
    border-radius: 4px;
    width: 100%;
}

.formInput:focus {
    outline: none;
}

.inputCommentData {
    background-color: white;
}

.formInputFileUploadContainer {
    display: flex;
    width: 100%;
    border-radius: 4px;
    border: 1px dashed var(--gray-color-4);
}

.formInputFileUpload {
    width: 100%;
}

.formInputFileUpload label {
    height: 100px !important;
    border: none;
    width: 100%;
}

.formInputFileUpload svg {
    color: var(--gray-color-4);
}

.DescriptionPreviewImage {
    height: 100px;
    width: auto;
    transition: all 0.3s ease-in-out;
}

.DescriptionPreviewImage:hover {
    box-shadow: 0px 0px 5px 0px var(--gray-color-4);
    transform: scale(1.1);
    background-color: white;
}

.bottomActionButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.modelPreviewContainer {
    width: 90vw;
    background-color: #fff;
}

.modelPreviewHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--gray-color-4);
}
.modelPreviewBodyContainer{
    max-height: 70vh;
    overflow-y: auto;
}
.modelPreviewFooterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    border-top: 1px solid var(--gray-color-4);
    gap: 10px;
}


.caroselImageListContainer {
    width: 100%;
    background-color: #fff;
}

.caroselImageItemContainer {
    width: 100%;
    display: flex;
    margin: 10px 0px 10px 0px;
    box-shadow: 0px 0px 2px 0px var(--gray-color-4);
}

.caroselImageContainer {
    width: 100%;
    display: flex;
}

.caroselImageItem {
    width: 100%;
}

.caroselImageRemoveContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-color-4);
    color: var(--error-color-1);
    font-size: 30px;
    width: 70px;
}

.caroselInsertContainer {
    display: flex;
    gap: 10px;
}

.caroselFileUploadContainer {
    background-color: #fff;
    border: 1px dashed var(--gray-color-4);
}

.caroselFileUploadContainer label {
    border: none;
}

.caroselButtonContainer {
    height: inherit;
}
.caroselPreviewBodyContainer{
    display: flex;   
}
.caroselPreviewContainer{
    width: 100%;
}
.caroselSliderContainer{
    width: 100px;
    height: inherit;
    background-color: red;
}

.newsCaroselImageListContainer {
    width: 100%;
    background-color: #fff;
}

.newsCaroselImageItemContainer {
    width: 100%;
    display: flex;
    margin: 10px 0px 10px 0px;
    box-shadow: 0px 0px 2px 0px var(--gray-color-4);
}

.newsCaroselImageContainer {
    width: 100%;
    display: flex;
}

.newsCaroselImageItem {
    width: 100%;
}

.newsCaroselImageRemoveContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-color-4);
    color: var(--error-color-1);
    font-size: 30px;
    width: 70px;
}

.newsCaroselInsertContainer {
    display: flex;
    gap: 10px;
}

.newsCaroselFileUploadContainer {
    background-color: #fff;
    border: 1px dashed var(--gray-color-4);
}

.newsCaroselFileUploadContainer label {
    border: none;
}

.newsCaroselButtonContainer {
    height: inherit;
}
.newsCaroselPreviewBodyContainer{
    display: flex;   
}
.newsCaroselPreviewContainer{
    width: 100%;
}
.newsCaroselSliderContainer{
    width: 100px;
    height: inherit;
    background-color: red;
}