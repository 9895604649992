.AppBar {
    background: #01234a;
    background: linear-gradient(180deg, rgba(1, 35, 74, 1) 0%, rgba(1, 35, 90, 1) 100%);
    padding: 0px 10px 0px 10px;
    height: 60px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
}

.rootContainer {
    height: 100%;
    width: 100%;
    display: flex;
}

.menuActionButtonContainer {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    color: #ffffff;
    align-items: center;
    width: 100px;
}

.logoLinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.logo {
    max-width: 200px;
    height: auto;
}

.actionButtonRootContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    /* width: 100%; */
}

.actionButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
}

.actionButtonWrapper {
    background-color: transparent;
}


.newsPopuMenuDetailContainer {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    width: 250px;
    height: auto;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 1000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.newsPopuMenuDetailContainer::after {
    content: " ";
    position: absolute;
    right: 10px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
}

.newsPopuMenuDetail {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
}

.newsPopuMenuItemContainer {
    margin-bottom: 3px;
    padding: 5px;
    border-bottom: 0.5px solid #e6e6e6;
}

.newsPopuMenuItemContainer:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}

.newsPopuMenuItemTitle {
    font-size: 14px;
    font-weight: 500;
    text-align: start;
    color: #0063c3;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
    white-space: nowrap;
}

.newsPopuMenuItemMoreDetailContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.newsPopuMenuItemDate {
    font-size: 12px;
    font-weight: 400;
    text-align: start;
    color: #3f3f3f;
}

.newsPopuMenuItemMore {
    font-size: 12px;
    font-weight: 400;
    text-align: end;
    color: #0063c3;
}

.newsPopuMenuShowAll {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    color: #0063c3;
}

.newsPopuMenuItemShowAllMoreLink {
    text-decoration: none;
    color: inherit;
}


.userPopupMenuContainer {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    width: 150px;
    height: auto;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 1000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.userPopupMenuContainer::after {
    content: " ";
    position: absolute;
    right: 10px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
}

.userPopuMenuDetail {}

.userPopuMenuItem {
    color: #0063c3;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.userPopuMenuItem>svg {
    margin-right: 5px;
}

.userPopuMenuItem:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}




.sectionRootContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    text-align: start;
    color: rgb(45, 45, 45);
}

.sectionContainer {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.pageHaderContainer {
    display: flex;
}

.pageTitle {
    font-size: 23px;
    font-weight: 500;
    text-align: start;
    color: #0063c3;
    margin-bottom: 10px;
    width: 100%;
    cursor: pointer;
}

.pageCloseButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 50px;

}

.sectionNameAccordionSummary {
    background-color: #f4f4f4;
}

.sectionNameTitle {
    font-size: 16px;
    font-weight: bold;
    text-align: start;
    color: #0063c3;
}

.menuItem {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border-bottom: 0.5px solid #e6e6e6;
}

.menuItem:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}



.sideMenuContainer {
    width: 100%;
}

.sideMenuRootContainer {
    background: rgba(1, 35, 90, 1);
    background: linear-gradient(180deg, rgba(1, 35, 90, 1) 0%, #024f99 50%, #0063c3 100%);
    height: calc(100vh - 60px);
    overflow-y: auto;

}

.pageSideMenuContainer {
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
}

.pageAccordion,
.sectionAccordion {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    border-radius: 0px !important;
    background-color: transparent !important;
}

.pageAccordionSummary {
    /* background-color: var(--gray-color-4) !important; */
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;

}

.pageExpendIcon {
    color: #ffffff;
}

.ActivePage {
    /* color: var(--primary-color-1) !important; */
    font-weight: bold;
}

.ActivePage svg:first-child {
    color: var(--primary-color-1) !important;
}

.pageAccordionSummary {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    height: 35px !important;
    min-height: 35px !important;
}

.sectionExpendIcon {
    color: #ffffff;
}

.sectionAccordionSummary {
    margin: 0px 0px 0px 10px !important;
    padding: 0px 0px 0px 0px !important;
    height: 35px !important;
    min-height: 35px !important;
}

.sidemenuPageTitle {
    font-size: var(--font-size-5);
    color: #ffffff;
    margin-left: 10px;
    margin-top: 5px;
    width: 100%;
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.pageIconImage {
    width: 15px;
    height: 15px;
}

.pageAccordionDetails,
.sectionAccordionDetails {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
}

.sectionAccordionSummary {
    /* background-color: var(--gray-color-5) !important; */
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    border: none !important;
}

.sectionAccordionDetails {
    background-color: #024f99 !important;
}

.sectionAccordion {
    background-color: var(--gray-color-1);
}

.sidemenuSectionTitle {
    font-size: var(--font-size-6);
    color: var(--gray-color-5);
}

.sectionIcon {
    font-size: var(--font-size-8);
}

.sidemenuTitle {
    font-size: var(--font-size-7);
    color: #ffffff;
    padding-left: 25px !important;
    padding: 1px 5px 1px 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.sidemenuTitle:hover {
    cursor: pointer;
    background-color: #01234a;
}

.pageLinkIcon {
    color: #ffffff;
    font-size: var(--font-size-5);
    margin: 10px 10px 10px 10px;
}

.tutorialVideoContainer {
    width: 500px;
    height: auto;
    background-color: #ffffff;
    /* border-radius: 15px; */
    max-width: 90vw;
}

.tutorialVideoHeader {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tutorialVideoTitle {
    font-size: var(--font-size-4);
    color: var(--gray-color-2);
    font-weight: 500;
}

.tutorialVideoClose {
    cursor: pointer;
    font-size: var(--font-size-4);
    color: var(--gray-color-2);
}

.tutorialVideoBody {
    padding: 10px;
    width: 100%;
}
.tutorialVideoFooter{
    padding: 0px 10px 10px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.tutorialVideo {
    width: 100%;
    height: auto
}