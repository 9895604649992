.bgImage {
    padding: 20px;
    background-size: cover;
    z-index: -1
}

.headerCard {
    padding: 10px;
    /* box-shadow: 0px 0px 5px 0px #ccc; */
}

.headerUserName {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #676767;
}

.headerWelComeText {
    display: block;
    font-size: var(--font-size-4);
    font-weight: 500;
    color: var(--gray-color-6);
}

.headerWelComeReportText {
    display: block;
    letter-spacing: 2px;
    font-size: var(--font-size-1);
    font-weight: 600;
    color: var(--gray-color-6);
}

.headerWelComeTextDetail {
    line-height: 21px;
    font-size: var(--font-size-5);
    color: var(--gray-color-6);
    text-align: justify;
}

.headerTutorialButton {
    background-color: transparent;
    color: var(--gray-color-6);
    border-radius: 20px;
    border: 1px solid var(--gray-color-6);
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
    padding: 5px 20px 5px 20px;
    /* margin-bottom: 10px; */
}

.headerImageRootContainer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
    top: -50px;
}

.headerImage {
    width: 70%;
    height: auto;
    object-fit: contain;
}

.tutorialRootContainer {
    width: 500px;
    background-color: #fff;
}

.tutorialHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #005bc5;
    background-image: linear-gradient(to top right, #005bc5, #00b6e4);
    color: white;
    border: none;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
}

.tutorialTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tutorialBodyContainer {
    margin: 0px;
    padding: 0px;
}

.tutorialVideo {
    width: 100%;
    height: 300px;
}
