.SummarySectionImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;
    background-color: #ccc;
    box-shadow: 0px 0px 5px 0px #ccc;
}

.SummarySectionImage {
    width: 100%;
    height: auto;
}

.SummarySectionDescription {
    width: 100%;
    height: 100px;
    font-size: 16px;
}

.SummarySectionPreview {
    background-color: #fff;
    border: 2px solid #ccc;
    box-shadow: 0px 0px 5px 0px #ccc;
}