.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: 20px;
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.topRightButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.searchControl {
    width: 100%;
    height: 32px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 8px;
    outline: none;
}

.totalCount {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: 600;
}

.tableActionButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.tableActionButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnActionContainer {
    color: var(--gray-color-3);

}

.btnAction {
    font-size: var(--font-size-5) !important;
    color: var(--gray-color-3) !important;
}

.btnAction:hover {
    background-color: var(--gray-color-2) !important;
    color: var(--gray-color-6) !important;
}

.projectName {
    font-size: var(--font-size-6);
    margin: 0px 3px 0px 3px;
    padding: 0px 5px 0px 5px;
    border-radius: 3px;
    border: 0.5px solid #ccc;
}

.projectName:hover {
    background-color: #000 !important;
    color: #fff;
}

.userSearchContainer {
    width: 100%;
    position: relative;
}

.userSearchListContainer {
    position: absolute;
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 0px 0px 4px 4px;
    ;
    padding: 5px;
}

.userSearchItem {
    display: flex;
    gap: 5px;
    padding: 5px 0px 5px 0px;
}

.userSearchItem:hover {
    background-color: var(--gray-color-5);
}

.username {
    font-size: var(--font-size-7);
    font-weight: 600;
    color: var(--gray-color-2);
}

.usermail {
    font-size: var(--font-size-8);
    padding: 2px 10px 2px 10px;
    background-color: var(--gray-color-3);
    color: var(--gray-color-6);
    border-radius: 4px;
}

.errorMessage {
    color: var(--error-color-1);
}

.projectRootContainer {
    width: 400px;
    max-width: 90vw;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #ccc;
}

.projectContainer {
    padding: 10px;
}

.projectHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.projectHeader {
    font-size: 20px;
    font-weight: 600;
    color: var(--gray-color-2)
}

.btnClose {
    font-size: 20px;
    color: var(--gray-color-3);
    cursor: pointer;
}

.projectBodyContainer {
    padding: 0px;
}

.addProjectContainer {
    margin-top: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.actionButtonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.ddlDRTProjectSelect {
    width: 100px;
    color: #ccc;
}

.drtSelectedProjectItem{
    margin: 0px 8px 0px 0px;
    padding: 1px 5px 1px 5px;
    background-color: #eee;
    color: black;
    border-radius: 3px;
    border: 1px solid #ccc;
}
