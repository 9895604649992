.sectionRootContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

@media only screen and (max-width: 992px) {
    .sectionRootContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media only screen and (min-width: 992px) {
    .sectionRootContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.sectionContainer {
    width: 100% !important;
}

.sectionHeaderContainer {
    width: 100%;
    border-bottom: 2px solid var(--primary-color-1);
}

.sectionHeader {
    background-color: var(--primary-color-1);
    color: white;
    font-weight: 600;
    font-size: var(--font-size-3);
    padding: 3px 10px 3px 10px;
}

.sectionBodyContainer {
    width: 100%;
    margin-top: 10px;
}

.reportRootContainer {
    padding: 5px;

}

.reportContainer {
    display: flex;
    border: 1px solid var(--gray-color-5);
    background-color: #fff;
    border-radius: 10px;
}

.reportContainer:hover {
    border: 1px solid var(--gray-color-5);
    background-color: var(--gray-color-5);
}

.cardImage {
    width: 20%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 3px solid #fff;
}

.reportDetails {
    width: 80%;
    padding: 5px;
}

.reportTitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: var(--font-size-6);
    color: var(--gray-color-2);
}

.reportTitleToolTip {
    display: none;
}

.reportTitle:hover+.reportTitleToolTip {
    position: absolute;
    display: block;
    font-size: var(--font-size-6);
    color: var(--gray-color-2);
    background-color: #fff;
    padding: 2px 10px 2px 10px;
    border-radius: 3px;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 0px 3px 0px #ccc;
}


.reportDescription {
    font-size: var(--font-size-7);
    color: var(--gray-color-3);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.reportDescriptionToolTip {
    display: none;
}

.reportDescription:hover+.reportDescriptionToolTip {
    position: absolute;
    display: block;
    font-size: var(--font-size-7);
    color: var(--gray-color-3);
    background-color: #fff;
    padding: 2px 10px 2px 10px;
    border-radius: 3px;
    border: 0.5px solid #eeeeee;
    box-shadow: 0px 0px 3px 0px #ccc;
}