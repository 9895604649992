.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: 20px;
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.subSectionTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.subSectionBottomContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.slugContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.slugLink {
    font-size: var(--font-size-6);
    color: var(--gray-color-3);
    cursor: pointer;
}

.tableActionButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnActionContainer {
    color: var(--gray-color-3);

}

.btnAction {
    font-size: var(--font-size-5) !important;
    color: var(--gray-color-3) !important;
}

.btnAction:hover {
    background-color: var(--gray-color-2) !important;
    color: var(--gray-color-6) !important;
}



.pageIcon {
    height: 40px;
    width: 40px;
    padding: 10px;
    background-color: var(--primary-color-1);
    box-shadow: 0px 0px 2px 0px #cccccc;
    border-radius: 3px;
    cursor: pointer;
    position: absolute;
    top: 3px;
    right: 3px;
}

.editRootContainer {
    width: 350px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #cccccc;
    border: 1px solid #cccccc;
    background-color: #fff;
}

.editContainer {
    padding: 5px;
    width: 100%;
}

.editHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.editTitle {
    font-size: var(--font-size-3);
    color: var(--gray-color-3);
    font-weight: 600;
    text-transform: capitalize;
}

.closeIcon {
    cursor: pointer;
    font-size: var(--font-size-3);
    color: var(--gray-color-3);
}

.formContainer {
    margin-top: 10px;
}

.formTitle {
    font-size: var(--font-size-6);
    color: var(--gray-color-2);
    font-weight: 500;
}

.formInput {
    height: 31px;
    border: 1.5px solid var(--gray-color-4);
    border-radius: 4px;
    color: var(--gray-color-2);
    padding: 0px 3px 0px 3px;
    width: 100%;
}

.formInput[disabled] {
    background-color: var(--gray-color-5);
    cursor: pointer;
}

.formInput:focus {
    outline: none;
}

.fileUploader label {
    border: 1.5px solid var(--gray-color-4);
}

.fileUploader {
    width: 100%;
    position: relative;
}
.pageTableIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.pagetableIcon {
    height: 30px;
    width: 30px;
    padding: 5px;
    background-color: var(--gray-color-3);
    box-shadow: 0px 0px 2px 0px #cccccc;
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s ease;
}
.pagetableIcon:hover {
    background-color: var(--gray-color-2);
    box-shadow: 0px 0px 5px 0px #cccccc;
    transform: scale(1.1);
}

.editFooterContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}