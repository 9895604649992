.AppBar {
    background: #01234a;
    background: linear-gradient(180deg, rgba(1, 35, 74, 1) 0%, rgba(1, 35, 90, 1) 100%);
    padding: 0px 10px 0px 10px;
    height: 60px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
}

.rootContainer {
    height: 100%;
    width: 100%;
    display: flex;
}

.menuActionButtonContainer {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    color: #ffffff;
    align-items: center;
    width: 100px;
}

.logoLinkContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.logo {
    max-width: 200px;
    height: auto;
}

.actionButtonRootContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    /* width: 100%; */
}

.actionButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
}

.actionButtonWrapper {
    background-color: transparent;
}


.userPopupMenuContainer {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 10px;
    width: 150px;
    height: auto;
    position: absolute;
    top: 50px;
    right: 0px;
    z-index: 1000;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
}

.userPopupMenuContainer::after {
    content: " ";
    position: absolute;
    right: 10px;
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
}

.userPopuMenuDetail {}

.userPopuMenuItem {
    color: #0063c3;
    font-size: 14px;
    font-weight: 500;
    padding: 5px;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}

.userPopuMenuItem>svg {
    margin-right: 5px;
}

.userPopuMenuItem:hover {
    background-color: #e6e6e6;
    cursor: pointer;
}

.sideMenuRootContainer {
    background: rgba(1, 35, 90, 1);
    background: linear-gradient(180deg, rgba(1, 35, 90, 1) 0%, #024f99 50%, #0063c3 100%);
    height: calc(100vh - 51px);
    overflow-y: auto;

}

.pageSideMenuContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: start;
    color: #ffffff;
    width: 100%;
    padding: 5px;
    font-size: 18px;
    gap: 5px;
    border-bottom: 0.5px solid #ccc;
}

.pageSideMenuContainerActive,
.pageSideMenuContainer:hover {
    background-color: #0063c3;
    cursor: pointer;
}