.navigate_item {
    font-size: var(--font-size-6);
}

.page_title {
    font-size: 20px;
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.checkbox_container {
    cursor: pointer;
}

.checkbox_container>* {
    font-size: 20px;
}

.custom_header_container {
    padding-top: 16px;
    padding-bottom: 16px;
    height: 100%;
    width: 100%;
}

.custom_header_toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom_header_label {
    white-space: wrap;
    cursor: pointer;
    word-break: break-all;
}

.custom_header_filter {
    padding: 2px 5px 2px 5px;
    border-radius: 3px;
    cursor: pointer;
}

.custom_header_filter:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.custom_header_filter>i {
    animation: 0.5s ease-in-out;
    visibility: hidden;
}

.table_wrapper {
    position: relative;
}

.table_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 8px;
}

.table_button_container {
    display: flex;
    column-gap: 10px;
    justify-content: end;
}

.table_header_user_search {
    background-color: white;
    width: 100%;
}

.table_header_user_search input {
    padding: 4px 12px 4px 12px;
}

.table_header_report_search {
    background-color: white;
    height: 31px;
    font-size: 16px;
    width: 100%;
}

.table_header_report_search > div > div {
    height: 31px !important;
}