.addCommentTitleContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.userAvtarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.userAvtar{
    font-size: var(--font-size-1);
    color: var(--gray-color-2);
}
.username{
    font-size: var(--font-size-6);
    font-weight: bold;
    color: var(--gray-color-2);
}
.addCommentTitle {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    color: #0063C3;
}
.messageSummery{
    font-size: var(--font-size-6);
    color: var(--gray-color-3);
    margin-top: -10px;
}
.inputCommentData{
    background-color: white;
}

.addCommentButtonContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.commentListContainer{

}
.commentList{
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #ccc;
}
.commentListHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.commentListHeaderUser{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #0063C3;
}
.commentListHeaderDate{
    font-size: 12px;
    color: #4f4f4f;
}
.textareaComment{
    width: 100%;
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;
    outline: none;
}