.tutorialVideoContainer {
    width: 500px;
    max-width: 80vw;
    height: auto;
    background-color: #ffffff;
    /* border-radius: 15px; */
    border: 1px solid var(--gray-color-3);
    box-shadow: 0px 0px 5px 0px var(--gray-color-3);
    position: fixed;
    top: 100px;
    left: calc(50% - 250px);
    z-index: 100;
}

.tutorialVideoHeader {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* .tutorialVideoHeader:hover {
    cursor: move;
    background-color: var(--gray-color-5);
    box-shadow: 0px 0px 3px 0px var(--gray-color-3) inset;
} */

.tutorialVideoTitle {
    font-size: var(--font-size-4);
    color: #000000;
    font-weight: 600;
}

.tutorialVideoTitle:hover {
    cursor: move;
}

.tutorialVideoClose {
    cursor: pointer;
    font-size: var(--font-size-4);
    color: var(--gray-color-2);
}

.tutorialVideoBody {
    padding: 10px;
    width: 100%;
}

.tutorialVideo {
    width: 100%;
    height: auto
}

.tutorialVideoFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0px 10px 10px 10px;
}

.closeButton {
    padding: 2px 15px 2px 15px;
    background-color: var(--gray-color-3);
    color: #ffffff;
    border-radius: 10px;
    cursor: pointer;
    font-size: var(--font-size-6);
    font-weight: 500;
}