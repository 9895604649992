.dashboardTitleName {
    font-size: var(--font-size-4);
    font-weight: bold;
    color: var(--primary-color-1);
}
.tableauTopActionButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.tableauTopActionButtonIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    border-radius: 3px;
    /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75); */
    /* background-color: var(--gray-color-6); */
    background-color: var(--primary-color-dark-1);
    /* color: var(--gray-color-2); */
    /* color: #ffffff; */
    position: relative;
}

.tableauTopActionButtonIconContainer:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.tableauTopActionButtonIconContainerDisplayNone {
    display: none;
}

.tableauTopActionButtonIcon {
    font-size: var(--font-size-5);
    color: #ffffff;
}

.tableauTopActionButtonIconActive {
    color: #f1c40f;
}

.reportBuilderRootContainer {
    width: 400px;
    background-color: #fff;
}

.reportBuilderContainer {
    border: 1px solid #ccc;
}

.reportBuilderHeader {
    background-color: #0063C3;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reportBuilderHeaderTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    color: white;
    width: 100%;
}

.reportBuilderHeaderCloseButton {
    color: white;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.reportBuilderBody {
    padding: 10px;
}

.reportBuilderFooter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
}

.reportBuilderFooterButton {
    background-color: #0063C3;
    
    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

.downloadItemRootContainer {
    position: absolute;
    top: 35px;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    padding: 2px;
}

.downloadItemRootContainer::after {
    content: " ";
    position: absolute;
    right: calc(50% - 10px);
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
}

.downloadItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 3px;
}

.downloadItemContainer:hover {
    cursor: pointer;
    background-color: #f2f2f2;
}

.downloadItemIcon {
    font-size: 20px;
    color: #0063C3;
}

.downloadItemTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #0063C3;
}
