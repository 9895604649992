.card {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding: 10px;
    background-color: white;
    /* background: linear-gradient(to right,#E9F4FF,#e9f4ffa2); */
}

.OsTitle {
    font-size: 30px;
    font-weight: 600;
    color: var(--primary-color-dark-1);
    text-shadow: 0px 1px 1px var(--primary-color-2);
}

.graphDetailContainer {
    position: relative;
    width: 100%;
    height: 200px;
}

.detailContainer,
.detailCountContainer,
.graphContainer {
    height: 200px;
    width: 100%;
    position: absolute;
}

.detailContainer {
    z-index: 1;
}

.detailCountContainer {
    z-index: 2;
}

.graphContainer {
    z-index: 3;
}

.slice:hover {
    stroke: #333333;
    cursor: pointer;
    filter: drop-shadow(3px 3px 2px #333333)
}

.detail {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.OsIconContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.OsIconContainer>* {
    font-size: 50px;
}

.detailCount {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.OsCount {
    text-align: end;
    font-size: var(--font-size-3);
    font-weight: 600;
    color: var(--primary-color-1);
    text-shadow: 0px 1px 1px var(--primary-color-2);
}