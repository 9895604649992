.pageNotFoundRootContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f5;
}

.pageNotFoundContainer {
    min-height: 100px;
    max-width: 80vw;
    text-align: center;
}

.pageNotFoundTitle {
    margin-top: 20px;
    font-weight: 900;
    font-size: 50px;
    background: -webkit-linear-gradient(#0063c3, #00b6e4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* background-color: #0063c3;
    background-image: linear-gradient(to top right, #0063c3, #00b6e4); */
}

.pageNotFoundDetail {
    margin-top: 20px;
    font-weight: 900;
    font-size: 20px;
    background: -webkit-linear-gradient(#0063c3, #00b6e4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* background-color: #0063c3;
    background-image: linear-gradient(to top right, #0063c3, #00b6e4); */
}

.redirectToHome {
    font-size: 18px;
    color: #757575;
}