.landingPageHeaderbg {
    background: rgba(1, 35, 90, 1);
    background: linear-gradient(180deg, rgba(1, 35, 90, 1) 0%, #024f99 50%, #0063c3 100%);
    padding: 20px 0px 20px 0px;
}

.titleContainer {
    text-align: start;

}

.headerWelComeText {
    font-size: var(--font-size-1);
    color: var(--gray-color-6);
    font-weight: 300;
}

.headerWelComeNameText {
    font-size: var(--font-size-1);
    color: var(--gray-color-6);
    font-weight: 600;
}

.textSummery {
    font-size: var(--font-size-5);
    color: var(--gray-color-6);
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 30px;
    text-align: left;
}

.headerImageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.headerImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 68% 32% 70% 30% / 48% 47% 53% 52%;
}


@media only screen and (max-width: 992px) {
    .headerImageContainer {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .headerImageContainer {
        display: flex;
    }
}