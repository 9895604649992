.title {
    font-size: var(--font-size-3);
    font-weight: bold;
    color: var(--primary-color-1);
    margin-bottom: 5px;
}

.wrapper {
    height: 400px;
    margin-bottom: 50px;
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 3px 0px #cccccc;
    border-radius: 10px;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 5px;
}

.chart_container {
    width: 100%;
    height: 400px;
    box-sizing: border-box;
}

.cell {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.header {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.selectMonth {
    border: 1px solid #cccccc;
    padding: 5px 1px 5px 1px;
    border-radius: 5px;
}

.selectMonth:focus {
    outline: none;
}