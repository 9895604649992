.imageContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1/1;
}

.favouriteWidgetImage {
    width: 90%;
    /* height: auto; */
    border-radius: 80%;
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px 0px #676767;
    aspect-ratio: 1/1;
}

.FavouriteWidgetTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #8C8787;
    margin-top: 10px;
}


.topFavouriteshareIcon {
    cursor: pointer;
    color: #8C8787;
    margin-left: 5px;
}

