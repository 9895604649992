.navigateItem {
    font-size: var(--font-size-6);
}

.jump_item_container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.jump_item {
    width: 119px;
    margin: 5px 10px 5px 10px;
    padding: 0 5px 0 5px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    box-sizing: border-box;
    justify-content: space-between;
    font-size: var(--font-size-6);
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #0d6efd;
    color: #0d6efd;
}

.jump_item:hover {
    width: 139px;
    margin: 5px 0px 5px 0px;
    box-shadow: 0px 0px 3px 0px #0d6efd;
    color: white;
    background-color: #01234a;
}

