.loadingImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loadingImage {
    width: 300px;
    height: auto;
    position: absolute;
    top: 50vh;
}

.tableauDashboardName {
    font-size: var(--font-size-4);
    font-weight: bold;
    color: var(--primary-color-1);
}

.tableauTopActionButtonContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
}

.tableauTopActionButtonIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    border-radius: 3px;
    /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75); */
    /* background-color: var(--gray-color-6); */
    background-color: var(--primary-color-dark-1);
    /* color: var(--gray-color-2); */
    /* color: #ffffff; */
    position: relative;
}

.tableauTopActionButtonIconContainer:hover {
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.tableauTopActionButtonIconContainerDisplayNone {
    display: none;
}

.tableauTopActionButtonIcon {
    font-size: var(--font-size-5);
    color: #ffffff;
}

.tableauTopActionButtonIconActive {
    color: #f1c40f;
}

.reportBuilderRootContainer {
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.reportBuilderContainer {
    /* border: 1px solid #ccc; */
}

.reportBuilderHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-4);
}

.reportBuilderHeaderTitle {
    font-weight: bold;
    margin: 0;
    padding: 10px;
    color: var(--gray-color-2);
    width: 100%;
}

.reportBuilderHeaderCloseButton {
    color: var(--gray-color-2);
    padding: 10px;
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.reportBuilderBody {
    padding: 10px;
}

.reportBuilderImageContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    max-width: 100%;
    overflow-x: auto;
}

.reportBuilderImage {
    width: 100px;
    height: 100px;
    border-radius: 11px;
    box-shadow: 0px 0px 3px 0px #cccccc;
    margin: 3px;
}

.reportBuilderFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.reportBuilderFooterButton {
    background-color: var(--primary-color-dark-1);

    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
}

.reportBuilderExportContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.btnExportReportBuilderDataList {
    color: var(--primary-color-dark-1);
    border: none;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.downloadItemRootContainer {
    position: absolute;
    top: 35px;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    padding: 2px;
}

.downloadItemRootContainer::after {
    content: " ";
    position: absolute;
    right: calc(50% - 10px);
    top: -10px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 10px solid rgb(255, 255, 255);
}

.downloadItemContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 3px;
}

.downloadItemContainer:hover {
    cursor: pointer;
    background-color: #f2f2f2;
}

.downloadItemIcon {
    font-size: 20px;
    color: var(--primary-color-dark-1);
}

.downloadItemTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: var(--primary-color-dark-1);
}

.customViewRootContainer {
    width: 400px;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
}

.customViewContainer {
    /* border: 1px solid #ccc; */
}

.customViewHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customViewHeaderTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
    width: 100%;
}

.customViewHeaderCloseButton {
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.customViewBody {
    padding: 10px;
}

.customViewExistingCustomViewsContainer {}

.customViewExistingCustomViewsTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #454545;
}

.customViewExistingCustomViewsSelectContainer {}

.customViewExistingCustomViewsSelect {
    width: 100%;
    padding: 5px;
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
    color: #454545;
}

.customViewExistingCustomViewsButtonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.customViewExistingCustomViewsButton {
    background-color: var(--primary-color-dark-1);

    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.customViewSetCurrentCustomViewAsDefaultContainer {
    margin-top: 25px;
}

.customViewSetCurrentCustomViewAsDefaultTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #454545;
}

.customViewSetCurrentCustomViewAsDefaultButtonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.customViewSetCurrentCustomViewAsDefaultButton {
    background-color: var(--primary-color-dark-1);

    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.customViewNewCustomViewNameContainer {
    margin-top: 25px;
}

.customViewNewCustomViewNameTitle {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    color: #454545;
}

.customViewNewCustomViewNameInputContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.customViewNewCustomViewNameInput {
    width: 100%;
    padding: 5px;
    height: 35px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-size: 16px;
    color: #454545;
}

.customViewNewCustomViewNameButtonContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.customViewNewCustomViewNameButton {
    background-color: var(--primary-color-dark-1);

    color: white;
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.tabsDdlTabRootContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 100%;
}

.ddlTabsRootContainer {
    position: relative;
}

.ddlTabsIcon {
    color: var(--gray-color-4);
    cursor: pointer;
}

.ddlTabsContainer {
    padding: 5px;
    box-shadow: 0px 0px 3px 0px #ccc;
    border-radius: 5px;
    border: 1px solid #ccc;
    position: absolute;
    background-color: white;
    z-index: 1000;
}

.ddlTabLink {
    text-decoration: none;
    color: #454545;
    font-size: var(--font-size-6);
    padding: 5px;
    display: block;
    text-wrap: nowrap;
}

.tabsRootContainer .rts___tabs,
.tabsRootContainer .rts___tabs___container {
    margin: 0px !important;
    padding: 0px !important;
}

.tabsRootContainer {
    border-bottom: 1px solid var(--primary-color-dark-1);
    margin-bottom: 2px;
    width: calc(100% - 30px);
}

.tableauTabs {
    background-color: var(--primary-color-2);
    border-radius: 0px;
    border: none;
    /* margin: 5px 0px 5px 0px; */
}

.tableauTabItem {
    /* margin: 0px 5px 0px 5px; */
    /* padding: 0px 5px 0px 5px; */
    margin: 0px 2px 0px 0px;
    padding: 0px 10px 0px 10px;
    height: 30px;
    border-radius: 0px;
    border: none;
    /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75); */
    background-color: var(--gray-color-5);
    color: #454545;
    font-size: var(--font-size-6);
}

.tableauTabItem:hover,
.tableauTabItemActive {
    background-color: var(--primary-color-3);
    color: var(--primary-color-1);
    font-weight: 600;
}


.tableauTabItemLink {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
}

.tableauTabItemLink:hover {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}




.dfRootContainer {
    width: 400px;
    max-width: 90vw;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
}

.dfContainer {
    /* border: 1px solid #ccc; */
}

.dfHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-4);
}

.dfHeaderTitle {
    font-weight: bold;
    margin: 0;
    padding: 10px;
    color: var(--gray-color-2);
    width: 100%;
}

.dfnote {
    font-size: var(--font-size-6);
    padding: 0px 10px 0px 10px;
    color: var(--gray-color-2);
}

.dfHeaderCloseButton {
    color: var(--gray-color-2);
    padding: 10px;
    cursor: pointer;
    height: 100%;
    width: 40px;
}

.dfBody {
    padding: 10px;
}

.dfDownloadContainer {
    max-height: 60vh;
    overflow-y: auto;
}

.dfItem {
    border: 1px solid var(--gray-color-3);
    border-radius: 5px;
    padding: 5px;
    margin: 5px 0px;
}

.dfItem:hover {
    cursor: pointer;
    border: 1px solid var(--primary-color-1);
    color: var(--primary-color-1);
}

.dfItemName {
    font-size: var(--font-size-6);
    font-weight: bold;
    margin: 0;
    color: inherit;
}