.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: var(--font-size-3);
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.pageTitleSummery {
    font-size: var(--font-size-7);
    color: var(--gray-color-2);
    font-weight: 400;
    margin-top: -5px;
}

.pageSubTitleContainer {
    border-bottom: 3px solid var(--primary-color-1);
    margin: 10px 0px 10px 0px;
}

.pageSubTitle {
    background-color: var(--primary-color-1);
    font-size: var(--font-size-4);
    color: var(--gray-color-5);
    font-weight: 600;
    text-transform: capitalize;
    padding: 5px 10px 5px 10px;
}

.newsRootContainer {
    border: 1px solid #ccc;
    background-color: #fff;
}

.newsRootContainerActive {
    border: 2px solid var(--primary-color-1);
}

.title {
    border: none;
    background-color: transparent;
    font-size: var(--font-size-1);
    color: var(--primary-color-1);
    font-weight: 600;
    text-transform: capitalize;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
    cursor: pointer;
    text-align: left;
}

.title:focus,
.title:focus-visible,
.title:active,
.title:hover {
    outline: none;
}

.newsUserContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    font-size: var(--font-size-5);
    color: var(--gray-color-2);
}

.split {
    font-weight: 900;
    margin: 0px 5px 0px 5px;
}

.ImgContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaImageRow {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}


.mediaContainer {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaImage,
.mediaVideo {
    width: 100%;
    max-height: 80vh;
    background-color: #fff;
}

.description {
    font-size: var(--font-size-5);
    color: var(--gray-color-1);
}