.landingPageHeaderbg {
    position: relative;
    overflow: hidden;
}

.bgHeaderImage {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background: url('./../../images/headerbgImage.png'); */
    background-size: cover;
    z-index: 1;
    /* filter: blur(3px); */
}

.landingPageHeaderContainer {
    position: relative;
    z-index: 2;
    height: 100%;
    /* background-color: #ffffff20; */
    background: rgba(1,35,90,1);
    background: linear-gradient(180deg, rgba(1,35,90,1) 0%, #024f99 50%, #0063c3 100%);

}

.titleContainer {
    text-align: start;

}

.headerWelComeText {
    font-size: var(--font-size-1);
    color: var(--gray-color-6);
    font-weight: 300;
}

.headerWelComeNameText {
    font-size: var(--font-size-1);
    color: var(--gray-color-6);
    font-weight: 600;
}

.textSummery {
    font-size: var(--font-size-5);
    color: var(--gray-color-6);
    font-weight: 400;
    margin-top: 15px;
    margin-bottom: 30px;
    text-align: left;
}

.headerImageContainer {
    max-width: 50%;
    height: 100%;
    position: absolute;
}

.headerImage {
    /* max-width: 660px; */
    height: 100%;
    position: relative;
    overflow-x: hidden;
    /* border-radius: 100% 0px 0px 0px; */
    -webkit-clip-path: polygon(50% 0%, 100% 0%, 100% 100%, 0% 100%);
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.headerTutorialButton {
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    padding: 3px 10px;
    border: 1px solid #fff;
    border-radius: 20px;
    font-weight: 300;
    /* margin-bottom: 20px; */
}


.tutorialRootContainer {
    width: 500px;
    background-color: #fff;
}

.tutorialHeaderContainer {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    padding: 10px;
    background-color: #0063c3;
    color: white;
    border: none;
    font-size: 18px;
    font-weight: bold;
    padding: 5px;
}

.tutorialTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.tutorialBodyContainer {
    margin: 0px;
    padding: 0px;
}

.tutorialVideo {
    width: 100%;
    height: 300px;
}


.headerNote {
    margin: 5px 0px 20px 0px;
    font-size: 15px;
    color: #0063C3;
    font-weight: 400;
    /* text-align: center; */
}