.ImageContainer {
    width: 100%;
    padding: 0px 10px 0px 10px;
}

.carouselImage {
    width: 100%;
    aspect-ratio: 16/9;
}

.newsContainer {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #e0e0e0;
}

.newsDetailContainer {
    padding: 10px;
    width: 100%;
    /* height: 100%; */
    background-color: #fff;
    border: 10px outset var(--primary-color-1);
    aspect-ratio: 16/9;
}

.newsTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-color-1);
    cursor: pointer;
}

.newsDescription {
    font-size: 16px;
    color: var(--gray-color-2);
    max-height: 101px;
    overflow-y: hidden;
}