.newsRootContainer {
    width: 100%;
    height: 280px;
    padding: 0px 5px 0px 5px;
}

.newsContainer {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border: 1px solid #e0e0e0;
}

.newsDetailContainer {
    padding: 10px;
}

.newsTitle {
    font-size: 18px;
    font-weight: bold;
    color: var(--primary-color-1);
    cursor: pointer;
}

.newsDescription {
    font-size: 16px;
    color: var(--gray-color-2);
}

.newsImageContainer {
    width: 100%;
    height: 180px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsImage {
    width: 100%;
    height: auto;
    max-height: 100%;
}