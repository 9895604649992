.navigateItem {
    font-size: var(--font-size-6);
}

.app {
    height: 100%;
}

.treeRoot {
    height: 100%;
}

.draggingSource {
    opacity: 0.3;
}

.placeholderContainer {
    position: relative;
}

.dropTarget {
    background-color: #e8f0fe;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnLeft {
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 10px;
}