.title {
    font-size: var(--font-size-3);
    font-weight: bold;
    color: var(--primary-color-1);
    margin-bottom: 5px;
}

.wrapper {
    height: 400px;
    margin-bottom: 50px;
    width: 100%;
    border: 1px solid #cccccc;
    box-shadow: 0px 0px 3px 0px #cccccc;
    border-radius: 10px;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    padding: 5px;
}

.chart_container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
}

.bar:hover:not(text) {
    stroke: #333333;
    cursor: pointer;
    filter: drop-shadow(3px 3px 2px #333333)
}