.navigateItem {
    font-size: var(--font-size-6);
}

.pageTitle {
    font-size: var(--font-size-3);
    color: #0063C3;
    font-weight: 600;
    text-transform: capitalize;
}

.pageTitleSummery {
    font-size: var(--font-size-7);
    color: var(--gray-color-2);
    font-weight: 400;
    margin-top: -5px;
}

.pageSubTitleContainer {
    border-bottom: 3px solid var(--primary-color-1);
    margin: 10px 0px 10px 0px;
}

.pageSubTitle {
    background-color: var(--primary-color-1);
    font-size: var(--font-size-4);
    color: var(--gray-color-5);
    font-weight: 600;
    text-transform: capitalize;
    padding: 5px 10px 5px 10px;
}

.topActionButtonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
}

.saveNewsModalRootContainer {
    background-color: #fff;
    width: 80vw;
}

.saveNewsModalHeaderContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0063C3;
}

.saveNewsModalHeaderTitleContainer {
    width: 100%;
    color: white;
    font-size: 20px;
    font-weight: bold;
    padding: 5px 0px 5px 10px;
}

.saveNewsModalHeaderCloseButtonContainer {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saveNewsModalHeaderCloseIcon {
    color: white;
    cursor: pointer;
    font-size: 25px;
}

.saveNewsModalBodyContainer {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.inputFormGroup {
    margin-top: 10px;
}

.inputFormTitle {
    font-size: 15px;
    font-weight: bold;
}

.inputFormControl {
    width: 100%;
    height: 30px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
    outline: none;
}

.inputFormControlTextArea {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 5px;
    outline: none;
}

.inputFormControlTextArea .ql-editor {
    height: 200px;
}

.saveNewsModalFooterContainer {
    width: 100%;
    display: flex;
    padding: 10px;
}

.visibilityContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.saveButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}


.tableContainer {
    width: 100%;
    max-height: 400px;
    overflow: auto;
    background-color: #ffffff;
    border: 1px solid #ccc;
}

.tableNewsData {
    width: 100%;
}

.tableNewsData,
.tableNewsData th,
.tableNewsData td {
    border: 1px solid #fff;
    border-collapse: collapse;
    padding: 3px 8px 0px 8px;
    vertical-align: top;
}

.tableNewsData thead {
    background-color: var(--gray-color-5);
    color: var(--gray-color-1);
    border-bottom: 2px solid var(--primary-color-1);
}

.tableNewsData thead th {
    text-align: center;
    font-size: var(--font-size-5);
    padding: 10px 0px 10px 0px;
}

.tableNewsData tbody tr {
    border-bottom: 2px solid #ccc;
}

.actionButtonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    height: 100%;
}

.actionButton {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}





.fileUploadRootContainer {
    display: flex;
    gap: 10px;
}

.fileUploadContainer {
    background-color: #fff;
    width: 100%;
    border: 1px dashed var(--gray-color-4);
}

.fileUploadContainer label {
    border: none;
}

.fileUploadImagePreviewContainer {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.fileUploadImagePreviewImage {
    width: 100px;
    height: auto;

}

.fileUploadImageRemove {}

.tableActionButtonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btnActionContainer {
    color: var(--gray-color-3);

}

.btnAction {
    font-size: var(--font-size-5) !important;
    color: var(--gray-color-3) !important;
}

.btnAction:hover {
    background-color: var(--gray-color-2) !important;
    color: var(--gray-color-6) !important;
}
.tableImage{
    width: 50px;
    height: auto;
    transition: all .2s;
}
.tableImage:hover{
    cursor: pointer;
    transform: scale(1.5);
    background-color: white;
    z-index: 1000;
}