.searchBoxInputRootContainer {
    width: 100%;
    height: 35px;
    background-color: #ffffff;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 0px 20px 0px 20px;
}

.searchBoxInputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.inputSearchBoxIcon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.inputSearchBox {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    padding: 0px 0px 0px 0px;
}

.searchResultRootContainer {
    position: relative;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #d9d9d9;
    border-top: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 100;
}

.searchResultWrapper {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
}

.searchResultItem {
    padding: 5px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 5px;
    background-color: #fff;
    box-shadow: 0px 0px 3px 0px #ccc;
    border-radius: 3px;
}
.searchResultItem:hover {
    background-color: #f2f2f2;
}

.searchResultItemTitle {
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #0063c3;
    width: 100%;
    text-align: start;
}

.searchResultItemDetail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.searchResultItemDetailText {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    color: #656262;
    width: 100%;
    text-align: start;
}