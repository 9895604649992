.flipCardContainer {
    background-color: transparent;
    width: 100%;
    perspective: 1000px;
    font-family: sans-serif;
    aspect-ratio: 1/1;
}

.title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
}

.cardImage {
    border-radius: 10px;
    aspect-ratio: 1/1;
}

.reportTitle {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-6);
}

.flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flipCardContainer:hover .flipCardInner {
    transform: rotateY(180deg);
}

.flipCardFront,
.flipCardBack {
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 5px 0px #ccc;
    border-radius: 10px;
}

.flipCardFront {
    background: linear-gradient(120deg,
            #469AEB 30%,
            #01234a 88%,
            #E9F4FF 40%,
            #01234a 78%);
    color: white;
}

.flipCardBack {
    background: linear-gradient(120deg,
            #469AEB 30%,
            #01234a 88%,
            #E9F4FF 40%,
            #01234a 78%);
    color: white;
    transform: rotateY(180deg);
}