.exclamationSymbol{
    margin-left: 5px;
    color: #777777;
}
.rootContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}
.bodyWrapper{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bodyContainer {
    min-width: 50px;
    min-height: 50px;
    background-color: white;
}
.closeButton{
    top: 5px;
    right: 20px;
    position: absolute;
    font-size: 50px;
    color: #fff;
}