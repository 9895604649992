.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #fff;
}

.loadingImage {
    width: 300px;
    height: auto;
}