.rootContainer {
    width: 100%;
    padding: 3px;
}

.dashboardContainer {
    background-color: #fff;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border: 1px solid #e5e5e5;
}
.carouselClass{
    background-color: #fff;
}
.imagecontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.reportImage {
    width: 100%;
    height: 110px;
}

.detailContainer {
    height: 100px;
    width: 100%;
}

.title {
    font-size: 12px;
    font-weight: 600;
    color: #0063c3;
    padding: 0px 5px 0px 5px;
}
.description{
    font-size: 12px;
    color: #666666;
    padding: 0px 5px 0px 5px;
}