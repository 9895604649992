.container{
    position: relative;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
}
.imgcontainer{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.imgLoad{
    width: 300px;
    max-width: 100vw;
}