body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-track {
    background: #B8DCFF;
}


body::-webkit-scrollbar-thumb {
    background: #01234a;
    box-shadow: inset 0 0 3px #cccccc;
}

body::-webkit-scrollbar-thumb:hover {
    background: #0063c3;
}