:root {
    --primary-color-dark-1: #01234a;
    --primary-color-1: #0063c3;
    --primary-color-2: #469AEB;
    --primary-color-3: #B8DCFF;
    --Secondary-color-1: #024F99;
    --Secondary-color-2: #E9F4FF;
    --error-color-1: #C70039;
    --active-color-1: #fd9e02;
    --gray-color-1: #000000;
    --gray-color-2: #58595b;
    --gray-color-3: #9b9b9d;
    --gray-color-4: #cccccc;
    --gray-color-5: #eeeeee;
    --gray-color-6: #ffffff;
    --font-size-1: 25px;
    --font-size-2: 23px;
    --font-size-3: 20px;
    --font-size-4: 18px;
    --font-size-5: 16px;
    --font-size-6: 14px;
    --font-size-7: 12px;
    --font-size-8: 10px;
    --font-size-9: 8px;
}


.link-text-decoration-none,
.link-text-decoration-none:hover,
.link-text-decoration-none:active,
.link-text-decoration-none:focus {
    text-decoration: none;
}

body {
    /* background-color: #fff; */
    font-family: 'Quicksand';
    color: #2f3133;
    /* background-image: url('./images/e2eVizBgImage.png'); */
    background: url('./images/e2eVizBgImage.png') no-repeat center center fixed;
    /* background: url('./images/e2eVizBgImage2.png') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

}

.fullscreen {
    height: 100vh;
    background: url('./images/e2eVizBgImage.png') no-repeat center center fixed;
    /* background: url('./images/e2eVizBgImage2.png') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    /* overflow-y: auto; */
}

.fullscreen-enabled {
    overflow-y: auto;
}

.mainContainer {
    width: 100%;
    padding: 0px 50px 0px 50px;
}

/* small devices (mobile) */
@media only screen and (max-width: 600px) {
    .mainContainer {
        padding: 0px 10px 0px 10px;
    }
}

/* Middle devices (tablets) */
@media only screen and (min-width: 600px) {
    .mainContainer {
        padding: 0px 20px 0px 20px;
    }
}

/* large devices (laptop) */
@media only screen and (min-width: 768px) {
    .mainContainer {
        padding: 0px 50px 0px 50px;
    }
}






.ReactQuill-DashboardComment .ql-editor {
    height: 150px;
}

.ReactQuill-DashboardComment .ql-toolbar.ql-snow {
    /* color: white;
    background-color: #0063c3;
    background-image: linear-gradient(to top right, #0063c3, #00b6e4); */
    background-color: #efefef;
}

.ReactQuill-NewsSave .ql-editor {
    height: 250px;
}

.ReactQuill-news-title .ql-editor {
    height: 90px;
}

.ReactQuill-news-description .ql-editor {
    height: 110px;
}

.aggrid-header .ag-theme-quartz .ag-header {
    background-color: #cccccc;
    /* background-image: linear-gradient(to top right, #005bc5, #00b6e4); */
}


.DesktopNav-Menu-Container .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary {
    align-items: end;
}

.hide-bullet ul {
    list-style: none;
    list-style-type: none;
}

.tableTab .rts___tabs,
.tableTab .rts___tabs___container {
    margin: 0px !important;
    padding: 0px !important;
}


.removeVideoProgress::-webkit-media-controls-timeline {
    display: none;
}

.rmsc .dropdown-content {
    z-index: 100 !important;
}

button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right,
button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    z-index: auto !important;
}