.rootContainer {
    align-items: center;
    text-align: center;
    max-width: 100%;
}

.reportItemContainer {
    margin: 10px;
}

.imgItem {
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.75);
}

.pageBrack:not(:last-child){
    page-break-before: always;
}

/* small devices (mobile) */
@media only screen and (max-width: 600px) {
    .imgItem {
        width: 100%;
        height: auto;
    }
}

/* Middle devices (tablets) */
@media only screen and (min-width: 600px) {
    .imgItem {
        min-width: 80%;
        height: auto;
    }
}

/* large devices (laptop) */
@media only screen and (min-width: 768px) {
    .imgItem {
        min-width: 50%;
        height: auto;
    }
}